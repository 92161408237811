.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #141414;
  border-bottom: 1px solid #ff9500;
}

.header-left,
.header-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1; /* This ensures that both the left and right sections take equal space */
}

.header-center {
  display: flex;
  justify-content: center; /* Center the content horizontally */
  flex-grow: 0; /* Prevent the center section from growing */
}

.dropdown {
  margin-right: 10px;
  padding: 5px 10px;
  border: 3px solid darkorange;
  border-radius: 4px;
  background-color: orange;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.visualize-button {
  padding: 15px 25px;
  margin-right: 10px;
  border: 2px solid orange;
  border-radius: 8px;
  background-color: black;
  color: orange;
  font-size: x-large;
  font-style: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clear-button {
  padding: 5px 15px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  background-color: orange;
  color: black;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.visualize-button:hover {
  background-color: orange;
  color: black;
  border: 2px solid black;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.clear-button:hover {
  background-color: black;
  color: #ff9500;
}

.toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  padding: 4px 8px;
  gap: 8px;
  border: 2px solid transparent;
  text-transform: uppercase;
  border-radius: 8px;
}

.toggle-button:hover {
  border: 2px solid white;
}

.toggle-button.walls-active {
  background-color: #007bff; /* Blue for walls */
  color: white;
}

.toggle-button.weights-active {
  background-color: #28a745; /* Green for weights */
  color: white;
}

/* Adjust the styles as needed */
