.node {
  width: 25px;
  height: 25px;
  border-top: 1px solid rgb(200, 200, 200);
  border-left: 1px solid rgb(200, 200, 200);
  display: inline-block;
  vertical-align: top; /* Add this line */
  position: relative; /* Added for positioning pseudo-element */
}

.icon-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.icon-container > img {
  width: auto;
  height: 25px;
}

/* Add these styles */
.grid > div:last-child .node {
  border-bottom: 1px solid rgb(200, 200, 200);
}

.grid > div .node:last-child {
  border-right: 1px solid rgb(200, 200, 200);
}

.node-start,
.node-finish {
  max-width: auto; /* Icon width is 100% of the node */
  max-height: 90%; /* Icon height is 100% of the node */
  opacity: 1; /* Adjust transparency as needed */
}

.node-weight::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px; /* Smaller width */
  height: 15px; /* Smaller height */
  background-color: rgb(255, 0, 0);
  transform: translate(-50%, -50%); /* Center the pseudo-element */
}

.node-start,
.node-finish,
.node-weight {
  user-select: none;
}

.node-wall {
  background-color: rgb(12, 53, 71);
}

.node-visited {
  animation-name: visitedAnimation;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

@keyframes visitedAnimation {
  0% {
    transform: scale(0.3);
    background-color: rgba(0, 0, 66, 0.75);
    border-radius: 100%;
  }

  50% {
    background-color: rgba(17, 104, 217, 0.75);
  }

  75% {
    transform: scale(1.2);
    background-color: rgba(0, 217, 159, 0.75);
  }

  100% {
    transform: scale(1);
    background-color: rgba(0, 190, 218, 0.75);
  }
}

.node-visited-final {
  transform: scale(1);
  background-color: rgba(0, 190, 218, 0.75);
}

.node-shortest-path {
  animation-name: shortestPath;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

@keyframes shortestPath {
  0% {
    transform: scale(0.6);
    background-color: rgb(255, 254, 106);
  }

  50% {
    transform: scale(1.2);
    background-color: rgb(255, 254, 106);
  }

  100% {
    transform: scale(1);
    background-color: rgb(255, 254, 106);
  }
}

.node-shortest-path-final {
  transform: scale(1);
  background-color: rgb(255, 254, 106);
}
