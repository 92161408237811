.under-header {
  margin: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: black;
}

.legend {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.section-container > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.finish-node-img {
  height: 25px;
  width: auto;
}

.image-container {
  display: flex;
  width: 25px;
  justify-content: center;
  align-items: center;
}

.start-node-img {
  height: 25px;
  width: auto;
}

.wall-legend > .image {
  width: 25px; /* Smaller width */
  height: 25px; /* Smaller height */
  background-color: rgb(12, 53, 71);
}

.weight-legend > .image-container > .image {
  content: "";
  width: 15px; /* Smaller width */
  height: 15px; /* Smaller height */
  background-color: rgb(255, 0, 0);
}

.visited-legend > .image {
  width: 25px;
  height: 25px;
  background-color: rgba(0, 190, 218, 0.75);
}

.shortest-legend > .image {
  width: 25px;
  height: 25px;
  background-color: rgb(255, 254, 106);
}

.flow-button {
  color: transparent;
  background-color: #000000;
  border: none;
  border: 2px solid orange;
  border-radius: 16px;
}

.Flow-Game-Solver > .flow-button > h1 {
  padding: 15px 25px;
  font-weight: 700px;
  font-size: medium;
  background-image: linear-gradient(
    to left,
    violet,
    rgb(172, 57, 255),
    rgb(0, 98, 255),
    rgb(0, 210, 0),
    yellow,
    orange,
    red
  );
  -webkit-background-clip: text;
  color: transparent;
}
